import React, { useState } from "react"
import { navigate } from "gatsby"
import LayoutCourse from "../../../../layouts/course"
import Seo from "../../../../components/seo"
import Stack from "../../../../components/stack"
import CourseFeedbackForm from "../../../../components/course-feedback-form"
import { getCourseNavigation } from "../../../../store/courses"
import CourseFooterNext from "../../../../components/course-footer-next"
import { postFeedback } from "../../../../api"

const Page = ({ location }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigation = getCourseNavigation({ courseId: "versteckte-ordnung" })

  return (
    <LayoutCourse
      as="form"
      navigation={navigation}
      onSubmit={async (event) => {
        event.preventDefault()
        setIsSubmitting(true)
        try {
          const form = new FormData(event.target)
          const feedback = Object.fromEntries(form.entries())
          await postFeedback({ feedback, title: "versteckte-ordnung" })
        } catch (error) {}
        setIsSubmitting(false)
        navigate("/kurse/hoelderlins-reisen/02-reiseerinnerung/silben/loesung")
      }}
      footer={
        <CourseFooterNext
          type="submit"
          value="Feedback senden"
          isLoading={isSubmitting}
        />
      }
    >
      <Seo title="Feedback" />
      <Stack>
        <CourseFeedbackForm positive={location?.state?.positive} />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
